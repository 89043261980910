import Image from 'next/image';
import styled from 'styled-components';
import {useState, useEffect} from 'react';
import {useTranslations} from 'next-intl';
import {AnimatePresence, motion} from 'motion/react';
import {highlightColor, panelistaPrimaryColor} from 'src/utils/constants';
import InteractiveAvatar from './InteractiveAvatar';
import getBrandStyle from 'src/utils/getBrandStyle';
import AnimatedHearts from '../AnimatedHearts';
import RemixIcon from '../RemixIcon';

const brandStyle = getBrandStyle();

const CircleLine = styled(motion.div)<{size: number}>`
  position: absolute;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => props.size}px;
  border: 1px solid ${panelistaPrimaryColor};
  left: calc(50% - ${(props) => props.size / 2}px);
  top: calc(50% - ${(props) => props.size / 2}px);
`;

const PhoneShape = styled.div`
  width: 270px;
  height: 520px;
  border-radius: 34px;
  position: absolute;
  left: calc(50% - 135px);
  top: calc(50% - 260px);
  border: 5px solid #f5ffff;
  box-shadow: 0px 14px 54px 0 rgba(21, 134, 140, 0.2);
  overflow: hidden;

  @media (max-width: 420px) {
    transform: scale(0.8);
  }
`;

const PhoneImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #b8baca;
`;

const Gradient = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 50%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.95) 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px 16px;
  color: white;
`;

const QuestionTitle = styled.span`
  font-weight: 500;
  margin-top: 10px;
`;

const CommentContainer = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.86);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  box-shadow: 0px 7px 42px rgba(29, 61, 62, 0.12);
  padding: 16px 20px;
  max-width: 300px;
  border: 1px solid rgba(255, 255, 255, 0.2);
`;

const FirstCommentContainer = styled(CommentContainer)`
  top: 48%;
  left: 0;

  @media (max-width: 1350px) {
    left: 100px;
  }

  @media (max-width: 1150px) {
    top: 52%;
    left: 2%;
    transform: scale(0.7);
  }

  @media (max-width: 550px) {
    left: -8%;
  }

  @media (max-width: 420px) {
    top: 49%;
    left: -12%;
  }

  @media (max-width: 350px) {
    top: 50%;
    transform: scale(0.5);
    left: -24%;
  }
`;

const SecondCommentContainer = styled(CommentContainer)`
  top: 30%;
  right: 0;

  @media (max-width: 1150px) {
    top: 42%;
    right: 2%;
    transform: scale(0.7);
  }

  @media (max-width: 550px) {
    right: -8%;
  }

  @media (max-width: 420px) {
    top: 36%;
    right: -12%;
  }

  @media (max-width: 350px) {
    transform: scale(0.5);
    right: -24%;
  }
`;

const CommentTopRow = styled.div`
  display: flex;
  align-items: center;
`;

const AvatarTextContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  font-size: 14px;
`;

const CommentText = styled.p`
  font-size: 15px;
  margin-top: 14px;

  strong {
    font-weight: normal;
    background-color: ${highlightColor};
    padding: 2px 4px;
    border-radius: 4px;
  }
`;

const CircleLines = styled.div`
  @media (max-width: 600px) {
    opacity: 0.6;
  }
`;

const HeartsBox = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: -10px;
  right: -10px;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  box-shadow: 0px 7px 30px rgba(29, 61, 62, 0.1);
  border-radius: 40px;
  padding: 10px 16px 10px 12px;
`;

const HeartButton = styled.button`
  position: relative;
  border: none;
  padding: 3px;
  z-index: 1;
  background-color: transparent;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 8px;
`;

const circleTransition = {ease: [0.25, 1, 0.5, 1], duration: 1};

const animatedWrapperStyle: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%'
};

const HeroPhoneFeature = () => {
  const t = useTranslations();
  const [isLiked, setLiked] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLiked(true);
    }, 2200);

    return () => clearTimeout(timeout);
  }, [setLiked]);

  return (
    <div className="absolute z-0 top-0 left-0 w-full h-full">
      <CircleLines>
        <AnimatePresence>
          <CircleLine
            key="circle1"
            size={400}
            animate={{scale: 1, opacity: 0.3}}
            initial={{opacity: 0, scale: 0.8}}
            transition={{delay: 0.3, ...circleTransition}}
          />
          <CircleLine
            key="circle2"
            size={540}
            animate={{scale: 1, opacity: 0.25}}
            initial={{opacity: 0, scale: 0.8}}
            transition={{delay: 0.4, ...circleTransition}}
          />
          <CircleLine
            key="circle3"
            size={680}
            animate={{scale: 1, opacity: 0.2}}
            initial={{opacity: 0, scale: 0.8}}
            transition={{delay: 0.5, ...circleTransition}}
          />
          <CircleLine
            key="circle4"
            size={820}
            animate={{scale: 1, opacity: 0.15}}
            initial={{opacity: 0, scale: 0.8}}
            transition={{delay: 0.6, ...circleTransition}}
          />
          <CircleLine
            key="circle5"
            size={960}
            animate={{scale: 1, opacity: 0.1}}
            initial={{opacity: 0, scale: 0.8}}
            transition={{delay: 0.7, ...circleTransition}}
          />
        </AnimatePresence>
      </CircleLines>

      <AnimatePresence>
        <motion.div
          key="phoneShape"
          style={animatedWrapperStyle}
          initial={{opacity: 0, scale: 1.05}}
          animate={{opacity: 1, scale: 1}}
          transition={{delay: 0.2, ease: [0.25, 1, 0.5, 1], duration: 1}}
        >
          <PhoneShape>
            <PhoneImageWrapper>
              <Image
                src="/images/heroPhoneImage.jpg"
                fill
                className="object-cover"
                sizes="300px"
                priority
                alt="Panelista UI"
              />
            </PhoneImageWrapper>

            <Gradient>
              <span className="text-xs opacity-80">Hillevi Brottare</span>
              <span className="text-xs opacity-50">Panelista Coach</span>
              <QuestionTitle>{t('home.hero phone question')}</QuestionTitle>
            </Gradient>
          </PhoneShape>
        </motion.div>

        <motion.div
          key="comment1"
          style={animatedWrapperStyle}
          initial={{opacity: 0, y: 20}}
          animate={{opacity: 1, y: 0}}
          transition={{delay: 1, ease: [0.25, 1, 0.5, 1], duration: 1}}
        >
          <FirstCommentContainer>
            <CommentTopRow>
              <InteractiveAvatar priority pictureSrc="/images/oscarPageAvatar.jpg" alt="Oscar" />
              <AvatarTextContent>
                <span>Oscar</span>
                <span className="opacity-50">{t('home.first comment time')}</span>
              </AvatarTextContent>
            </CommentTopRow>

            <CommentText>
              {t.rich('home.first comment text', {strong: (chunks) => <strong>{chunks}</strong>})}
            </CommentText>
          </FirstCommentContainer>
        </motion.div>

        <motion.div
          key="comment2"
          style={animatedWrapperStyle}
          initial={{opacity: 0, y: 20}}
          animate={{opacity: 1, y: 0}}
          transition={{delay: 1.2, ease: [0.25, 1, 0.5, 1], duration: 1}}
        >
          <SecondCommentContainer>
            <CommentTopRow>
              <InteractiveAvatar priority pictureSrc="/images/jessicaPageAvatar.jpg" alt="Jessica" />
              <AvatarTextContent>
                <span>Jessica</span>
                <span className="opacity-50">{t('home.second comment time')}</span>
              </AvatarTextContent>
            </CommentTopRow>

            <CommentText>
              {t.rich('home.second comment text', {strong: (chunks) => <strong>{chunks}</strong>})}
            </CommentText>

            <HeartsBox>
              <ButtonContainer>
                <HeartButton onClick={() => setLiked(!isLiked)}>
                  <RemixIcon name="heart-fill" color={isLiked ? 'var(--like-color)' : '#C8C8C8'} />
                </HeartButton>
                <AnimatedHearts brandStyle={brandStyle} active={isLiked} duration={1} />
              </ButtonContainer>
              {isLiked ? 5 : 4}
            </HeartsBox>
          </SecondCommentContainer>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default HeroPhoneFeature;
