'use client';

import Image from 'next/image';
import {useState} from 'react';
import styled from 'styled-components';
import {useTranslations} from 'next-intl';
import {AnimatePresence, motion} from 'motion/react';
import {highlightColor, panelistaDarkColor, panelistaLightColor, panelistaPrimaryColor} from 'src/utils/constants';
import {LandingButton, SectionTitleLegacy} from '../landingStyledComponents';
import {WidthContainer} from './LandingComponents';
import getUseCases from 'src/utils/getUseCases';
import RemixIcon from '../RemixIcon';

const compactThreshold = 700;

const WideContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Container = styled(WidthContainer)`
  margin-bottom: 50px;

  @media (max-width: 500px) {
    margin-bottom: 30px;
  }
`;

const UseCaseCard = styled(motion.div)`
  display: flex;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 8px 40px 0 rgba(0, 0, 0, 0.1);
  min-height: 550px;
  overflow: hidden;

  @media (max-width: 1300px) {
    min-height: 620px;
  }

  @media (max-width: 1100px) {
    min-height: 660px;
  }

  @media (max-width: 800px) {
    min-height: 600px;
  }

  @media (max-width: ${compactThreshold}px) {
    min-height: 460px;
  }

  @media (max-width: 500px) {
    box-shadow: 0px 8px 30px 0 rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 400px) {
    min-height: 490px;
  }

  @media (max-width: 320px) {
    min-height: 530px;
  }
`;

const Title = styled(SectionTitleLegacy)`
  text-align: center;
  padding-top: 20px;
  margin-bottom: 80px;

  @media (max-width: 1100px) {
    padding-top: 0;
    margin-bottom: 60px;
  }

  @media (max-width: 700px) {
    margin-bottom: 40px;
  }

  @media (max-width: 500px) {
    text-align: left;
  }
`;

const CompanySide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  padding: 50px;

  @media (max-width: 1000px) {
    padding: 40px;
  }

  @media (max-width: ${compactThreshold}px) {
    display: none;
  }
`;

const CompanyTitle = styled(SectionTitleLegacy)`
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 20px;
`;

const PersonSide = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${panelistaLightColor};
  padding: 50px;
  width: 50%;

  @media (max-width: 1000px) {
    padding: 40px;
  }

  @media (max-width: 500px) {
    padding: 20px;
  }
`;

const Paragraph = styled.p`
  font-size: 20px;

  strong {
    font-weight: inherit;
    background-color: ${highlightColor};
    padding: 2px 4px;
    border-radius: 4px;
  }

  @media (max-width: 900px) {
    font-size: 18px;
  }
`;

const PersonParagraph = styled(Paragraph)`
  position: relative;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 180px;

  @media (max-width: 900px) {
    font-size: 20px;
  }

  @media (max-width: ${compactThreshold}px) {
    margin-bottom: 20px;
  }

  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

const PersonText = styled.span`
  position: relative;
  font-size: 18px;
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.6);
  max-width: 260px;

  @media (max-width: 1100px) {
    font-size: 16px;
    max-width: 180px;
  }

  @media (max-width: ${compactThreshold}px) {
    font-weight: 600;
  }
`;

const ControlButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
`;

const ControlButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  line-height: 1;
  border: none;
  border-radius: 50px;
  background-color: ${panelistaPrimaryColor};
  width: 54px;
  height: 54px;
  margin: 0 10px;

  transition: filter 0.3s, transform 0.1s;

  &:hover {
    filter: brightness(105%);
    transform: translateY(-1px);
  }
`;

const Circles = styled.div`
  position: absolute;
  bottom: -260px;
  right: -100px;
  width: 480px;
  height: 480px;

  @media (max-width: 900px) {
    bottom: -300px;
    right: -140px;
  }

  @media (max-width: 440px) {
    bottom: -340px;
    right: -180px;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 280px;
  height: 280px;

  @media (max-width: 900px) {
    width: 240px;
    height: 240px;
  }

  @media (max-width: 440px) {
    width: 200px;
    height: 200px;
  }
`;

const Circle = styled.div<{size: number}>`
  position: absolute;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  left: calc(50% - ${(props) => props.size / 2}px);
  top: calc(50% - ${(props) => props.size / 2}px);
  border-radius: 300px;
  background-color: ${panelistaDarkColor};
  opacity: 0.12;
`;

const ReadMoreButton = styled(LandingButton)`
  display: flex;
  align-items: center;
  padding-left: 0;
`;

const MobileReadMoreWrapper = styled.div`
  position: relative;
  display: none;

  @media (max-width: ${compactThreshold}px) {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
  }
`;

const MobileCompanyName = styled.span`
  display: none;

  @media (max-width: ${compactThreshold}px) {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 6px;
  }
`;

const DesktopSpacer = styled.div`
  flex-grow: 1;

  @media (max-width: ${compactThreshold}px) {
    display: none;
  }
`;

const getAnimationProps = (direction: 'right' | 'left') => {
  const multiplier = direction === 'left' ? -1 : 1;

  return {
    initial: {opacity: 0, x: `${multiplier * 20}%`, scale: 0.95},
    animate: {opacity: 1, x: 0, scale: 1, transition: {ease: [0.25, 1, 0.5, 1]}},
    exit: {
      opacity: 0,
      x: `${multiplier * -1 * 20}%`,
      scale: 0.95,
      transition: {duration: 0.15, ease: [0.11, 0, 0.5, 0]}
    },
    transition: {duration: 0.2}
  };
};

const LandingUseCasesSection = () => {
  const t = useTranslations();
  const useCases = getUseCases(t);
  const [activeIndex, setActiveIndex] = useState(0);

  const activeUseCase = useCases[activeIndex];

  const canGoPrev = activeIndex !== 0;
  const canGoNext = activeIndex !== useCases.length - 1;

  const [animationProps, setAnimationProps] = useState(getAnimationProps('right'));

  const goToSlide = (index: number) => {
    setAnimationProps(getAnimationProps(index > activeIndex ? 'right' : 'left'));
    plausible('Browsing use cases');

    setTimeout(() => {
      setActiveIndex(index);
    }, 100);
  };

  const goNext = canGoNext ? () => goToSlide(activeIndex + 1) : null;
  const goPrev = canGoPrev ? () => goToSlide(activeIndex - 1) : null;

  const readMoreButton = activeUseCase.link ? (
    <ReadMoreButton href={activeUseCase.link} aria-label={activeUseCase.author}>
      {t('website.read the article')}
      <RemixIcon name="arrow-right-line" style={{marginLeft: 8, marginRight: -6}} />
    </ReadMoreButton>
  ) : null;

  return (
    <>
      <WideContainer>
        <Container id="use-cases">
          <Title>{t('website.use cases title')}</Title>
          <AnimatePresence initial={false} mode="wait">
            <UseCaseCard key={activeIndex} {...animationProps}>
              <CompanySide>
                <CompanyTitle>{activeUseCase.company}</CompanyTitle>
                <Paragraph>{activeUseCase.caseText}</Paragraph>

                <div className="grow" />

                {readMoreButton}
              </CompanySide>
              <PersonSide>
                <Circles>
                  <Circle size={480} />
                  <Circle size={380} />
                  <Circle size={280} />
                </Circles>

                <ImageWrapper>
                  <Image src={activeUseCase.imageSrc} width={280} height={280} alt={activeUseCase.company} />
                </ImageWrapper>

                <PersonParagraph>{activeUseCase.body}</PersonParagraph>

                <DesktopSpacer />
                <PersonText>{activeUseCase.author}</PersonText>
                <MobileCompanyName>{activeUseCase.company}</MobileCompanyName>

                <MobileReadMoreWrapper>{readMoreButton}</MobileReadMoreWrapper>
              </PersonSide>
            </UseCaseCard>
          </AnimatePresence>
        </Container>
      </WideContainer>

      <ControlButtons>
        <ControlButton onClick={goPrev} style={{opacity: canGoPrev ? 1 : 0.2}} aria-label={t('common.prev')}>
          <RemixIcon name="arrow-left-s-line" color="white" size={30} />
        </ControlButton>

        <ControlButton onClick={goNext} style={{opacity: canGoNext ? 1 : 0.2}} aria-label={t('common.next')}>
          <RemixIcon name="arrow-right-s-line" color="white" size={30} />
        </ControlButton>
      </ControlButtons>
    </>
  );
};

export default LandingUseCasesSection;
