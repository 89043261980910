import {motion} from 'motion/react';
import {twMerge} from 'tailwind-merge';
import RadixModal from './RadixModal';
import BaseButton from './BaseButton';

type Props = {
  onClose: () => void;
  children: React.ReactNode;
  mobileThreshold?: number;
  style?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  className?: string;
  hideCloseButton?: boolean;
  mobileTopOffset?: number;
  layout?: boolean;
  outsideScrollComponent?: React.ReactNode;
  contentId?: string;
  wrapperClassName?: string;
  closeOnEscape?: boolean;
  closeOnClickOutside?: boolean;
  title?: string;
  container?: React.RefObject<HTMLDivElement>;
};

const ResponsiveRadixModal = ({
  onClose,
  children,
  style,
  className,
  hideCloseButton,
  mobileTopOffset = 40,
  layout,
  outsideScrollComponent,
  wrapperStyle,
  contentId,
  wrapperClassName,
  closeOnEscape,
  closeOnClickOutside,
  title,
  container
}: Props) => {
  return (
    <RadixModal
      title={title}
      onClose={onClose}
      contentId={contentId}
      closeOnEscape={closeOnEscape}
      closeOnClickOutside={closeOnClickOutside}
      wrapperStyle={{'--top-offset': `${mobileTopOffset}px`} as React.CSSProperties}
      container={container}
    >
      <motion.div
        style={style}
        className={twMerge(
          'w-full bg-white relative rounded-2xl overflow-hidden max-[1000px]:absolute max-h-full z-[101] shadow-tall max-w-[600px] max-[600px]:absolute max-[600px]:h-[calc(100%-var(--top-offset))] max-[600px]:bottom-0 max-[600px]:rounded-b-none',
          className
        )}
        layout={layout}
      >
        <motion.div
          layoutScroll
          style={wrapperStyle}
          className={twMerge(
            'w-full overflow-auto max-h-[calc(100vh-var(--top-offset))] max-[600px]:relative max-[600px]:pt-[50px] max-[600px]:h-full',
            wrapperClassName
          )}
        >
          {children}

          {hideCloseButton ? null : (
            <motion.div className="absolute top-[10px] right-[10px]">
              <BaseButton
                variant="text"
                iconName="close-line"
                color="var(--gray-text)"
                iconSize={22}
                className="p-0 w-10 h-10 rounded-full"
                onClick={onClose}
              />
            </motion.div>
          )}
        </motion.div>

        {outsideScrollComponent}
      </motion.div>
    </RadixModal>
  );
};

export default ResponsiveRadixModal;
