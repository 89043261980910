import {usePresence} from 'motion/react';
import {type ForwardedRef, forwardRef, useEffect, useState} from 'react';

const defaultDurationMs = 400;

const defaultActiveStyle: React.CSSProperties = {opacity: 1};
const defaultInactiveStyle: React.CSSProperties = {opacity: 0};

type Props = {
  durationMs?: number;
  durationOutMs?: number;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  activeStyle?: React.CSSProperties;
  inactiveStyle?: React.CSSProperties;
  onClick?: any;
  forwardProps?: any;
  className?: string;
};

const Smooth = forwardRef(
  (
    {
      durationMs = defaultDurationMs,
      durationOutMs,
      children,
      style,
      activeStyle = defaultActiveStyle,
      inactiveStyle = defaultInactiveStyle,
      forwardProps,
      className
    }: Props,
    ref: ForwardedRef<any>
  ) => {
    const [isMounted, setMounted] = useState(false);
    const [isPresent, safeToRemove] = usePresence();

    const duration = isPresent ? durationMs : durationOutMs || durationMs;

    useEffect(() => {
      !isPresent && setTimeout(safeToRemove, duration);

      if (!isMounted) setMounted(true);
    }, [isPresent]);

    const currentStyle = isPresent && isMounted ? activeStyle : inactiveStyle;

    return (
      <div
        className={className}
        {...forwardProps}
        style={{
          transition: `all ${duration / 1000}s`,
          transitionTimingFunction: 'cubic-bezier(0.22, 1, 0.36, 1)',
          ...style,
          ...currentStyle
        }}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

export default Smooth;
